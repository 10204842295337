import { formatRelative } from 'date-fns'

export default function relativeDate(date, baseDate = new Date(), options) {
  if (!date) {
    return null
  }

  if (typeof date === 'number') {
    date = new Date(date)
  } else if (typeof date === 'string') {
    date = new Date(Date.parse(date))
  } else if (!(date instanceof Date)) {
    throw new Error(`unhandled date type ${typeof date}: ${date}`)
  }

  return formatRelative(date, baseDate, options)
}
