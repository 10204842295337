<template>
  <div>
    <PageTitle> Debug </PageTitle>

    <dl>
      <dt>Version</dt>
      <dd>{{ buildVersion }}</dd>
      <dt>Date</dt>
      <dd>{{ buildTime }}</dd>
      <dt>Backend</dt>
      <dd>{{ backend }}</dd>
    </dl>
  </div>
</template>

<script>
import { getBackend } from '../axios.js'
import PageTitle from '../components/generic/PageTitle.vue'
import relativeDate from '../filters/relativeDate.js'

export default {
  name: 'Debug',
  components: { PageTitle },
  computed: {
    backend() {
      return getBackend()
    },
    buildVersion() {
      return process.env.VUE_APP_VERSION
    },
    buildTime() {
      return new Date(parseInt(process.env.VUE_APP_BUILD_TIMESTAMP))
    },
  },
  filters: {
    relativeDate,
  },
}
</script>

<style scoped></style>
